<template>
  <div>
    <h2 class="admin-plan_edit_heading">現在利用プランの変更</h2>
    <div class="select-plan pb-20">
      <label class="select-plan-label">プランの選択</label>
      <base-select v-model="selectedPlanId" required>
        <option :value="0" selected>選択してください</option>
        <option
          v-for="plan in planData"
          v-show="plan.id !== companyPlanData.id"
          :key="plan.id"
          :value="plan.id"
        >
          {{ plan.name_ja }}
        </option>
      </base-select>
    </div>
    <div
      v-if="!isDefault(selectedPlanId)"
      class="edit-company-element__applicant"
    >
      <div class="row-column">
        <h2>応募者数</h2>
        <p>
          {{ applicantsCount }} /
          {{ selectedPlanData.applicant_limit }}
        </p>
      </div>
      <div v-show="selectedPlanData.applicant_limit < applicantsCount">
        <button-base
          class="btn-s"
          button-text="削除予定の応募者を表示"
          icon-file-name="search_white"
          :rejected-admin-ids="[2]"
          @click="onClickOpenDeleToApplicantsModal"
        />
        <button-base
          class="btn-s btn-download_csv"
          button-text="削除予定の応募者のCSVをダウンロード"
          icon-file-name="download_white"
          :rejected-admin-ids="[2]"
          @click="onClickDownloadApplicantsCsv"
        />
      </div>
    </div>
    <p
      v-show="selectedPlanData.applicant_limit < applicantsCount"
      class="attention pb-20"
    >
      ※
      選択しているプランは、登録可能な応募者数の上限を超えているため、応募者の削除が必要になります
    </p>
    <button-base
      class="apply-plan"
      button-text="現在利用プランを変更する"
      @click="updatePlan"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from 'vue';

import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import { mochicaPlans, getPlanDataById } from '@/defines/plan';

export default defineComponent({
  name: 'AdminEditPlan',
  components: {
    BaseSelect,
  },
  props: {
    planId: {
      type: Number,
      default: null,
    },
    applicantsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['onClickOpenModalSettingPlan', 'onSelectedPlanId'],
  setup(props, context) {
    const selectedPlanId = ref(0);

    // computed
    const planData = computed(() => [
      ...mochicaPlans.filter(plan => plan.is_show === true),
    ]);
    const selectedPlanData = computed(() => {
      if (parseInt(selectedPlanId.value) === 0) return {};
      const plan = getPlanDataById(parseInt(selectedPlanId.value));
      console.log(plan);
      return plan || {};
    });
    const companyPlanData = computed(() => {
      const plan = getPlanDataById(props.planId);
      return plan || {};
    });

    // methods
    const isDefault = planId => {
      return parseInt(planId) === 0;
    };

    const onClickOpenDeleToApplicantsModal = () => {
      context.emit('onClickOpenDeleToApplicantsModal');
    };

    const onClickDownloadApplicantsCsv = () => {
      context.emit('onClickDownloadApplicantsCsv');
    };

    const updatePlan = () => {
      const planId =
        parseInt(selectedPlanId.value) === 0
          ? null
          : parseInt(selectedPlanId.value);
      context.emit('onClickOpenModalSettingPlan', planId);
    };

    // watch
    watch(selectedPlanId, val => {
      const planId = getPlanDataById(parseInt(val));
      context.emit('onSelectedPlanId', planId !== undefined ? planId : null);
    });

    return {
      planData,
      selectedPlanId,
      selectedPlanData,
      companyPlanData,
      isDefault,
      onClickOpenDeleToApplicantsModal,
      onClickDownloadApplicantsCsv,
      updatePlan,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.pb-20 {
  padding-bottom: 20px;
}
.edit-company-element__applicant {
  display: flex;
  flex-direction: column;
  .row-column {
    margin-bottom: 20px;
  }
}
.apply-plan {
  width: 100%;
  height: 48px;
  border-radius: 24px !important;
}
.select-plan {
  :deep(.wrapper) {
    width: 320px;
  }

  :deep(.base-select) {
    height: 44px;
  }

  :deep(:after) {
    height: 22px;
  }
}
.select-plan-label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.attention {
  color: $mochica_color_red;
  margin-top: 20px;
}
.btn-download_csv {
  margin-left: 10px;
}
.admin-plan_edit_heading {
  padding: 10px 16px;
  background: $mochica_color_gray2;
  font-weight: bold;
  border-radius: 4px;
  font-size: $font_16;
  margin-bottom: 20px;
}
</style>
